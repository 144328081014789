html, body {
    height: 100%;
    /*overflow: hidden;*/
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: 'Raleway', sans-serif !important;
}

@font-face {
    font-family: 'Choppers';
    src: url('./fonts/choppers-personal-use.woff2') format('woff2'),
    url('./fonts/choppers-personal-use.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

body {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: center center;
    background-color: black;
}

* {
    box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6 {
    padding: 0;
    margin: 0;
}

h2 {
    font-family: Choppers;
    font-size: 30px;
    font-weight: 400;
}

#root {
    height: 100%;
}

.full-page-controls {
    position: fixed;
    right: 0;
}

.hide {
    display: none !important;
}

.show {
    display: block;
}

.slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    background-color: black;
}

.slide-bottom {
    -webkit-animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-5-3 1:48:15
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-top {
    0% {
        bottom: 0;
    }
    100% {
        bottom: calc(100% - 170px);
    }
}
@keyframes slide-top {
    0% {
        bottom: 0;
    }
    100% {
        bottom: calc(100% - 170px);
    }
}

@-webkit-keyframes slide-bottom {
    0% {
        bottom: calc(100% - 170px);
    }
    100% {
        bottom: 0;
    }
}
@keyframes slide-bottom {
    0% {
        bottom: calc(100% - 170px);
    }
    100% {
        bottom: 0;
    }
}

.tns-outer > button {
    display: none;
}

textarea:focus, input:focus, *:focus {
    outline: none;
}


a {
    color: #FFF;
    text-decoration: none;
    transition: padding-bottom 0.2s ease;
}

a:active {
    color: #FFF;
}